/* You can add global styles to this file, and also import other style files */
// @import "~@ng-select/ng-select/themes/material.theme.css";
//@import '~ngx-bar-rating/themes/br-default-theme';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import 'ngx-toastr/toastr';
$primary-color:#0C1A35;
$secondary:#002f6c ;
$white-color:#fff;
$red-color:#ba0c2f;
.text-success {
  color: #389d51!important;
}
.text-danger {
  color: #BA0C2F !important;
}
a.text-danger:focus, a.text-danger:hover {
  color: #BA0C2F !important;
}
.badge-danger {
  background-color: #BA0C2F;
}
.btn-danger {
  color: #fff;
  background-color: $red-color;
  border-color:$red-color;
}
.text-warning{color: $secondary;}
.text-dark,a.text-dark:focus, a.text-dark:hover {
  color:  $secondary !important;
}
#page-header-user-dropdown,#page-header-user-dropdown i{color: $secondary;}

.badge {
  max-width: 131px;
}

#page-topbar {
  background-color: #fff!important;
  z-index: 99999;

}
.bg-overlay {
  background-color: #a0aab9!important;
}

.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.clearfilter {
  margin-left: 16px;
  border-radius: 18px;
  background-color: #fcb92c;
  color: white;
  border: none;
  height: 29px;
  text-align: center;
}

.loader-table {
  overflow: hidden !important;
}

.bdr,
.select_date .form-control {
  border: 1px dotted black;
  border-style: groove;
  border-color: grey;
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: .7875rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
}

.bdr:before,
.select_date .form-control:before {
  position: absolute;
  color: $secondary !important;
  line-height: 28px;
  right: 10px;
  top: 0;
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\eb1d";
}


.bdr i {
  display: none;
}

.cldicon {
  position: absolute;
  margin-left: 9px;
  color: $secondary !important
}

.ng-select-container {
  height: 29px !important;
  min-height: 29px !important;
}
.ng-value-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: .7875rem !important;
}

// .scroller {
//   width: 100%;
//   height: 200px;
//   // overflow-y: scroll;
// }

// /* width */
// ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #eee;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #F5ECDB;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #F5ECDB;
// }

.trnId {
  background-color: #80808099;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;

}

.trnId3 {
  background-color: #13cf6b99;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
}

.trnId2 {
  background-color: #80808099;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #fcb92c !important;
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none !important;
}

.pt table {
  display: block;
  width: 100%;
}

.imageIcons {
  width: 55px;
  height: 55px;
  border-radius: 16px;
}


.image-upload {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 80px;
  height: 60px;
  border-style: ridge;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
  border-radius: 8px;

}

.upload-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upload-icon,
.close-icon {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

.uploaded-image {
  // position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.uploaded-image2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#image-preview {
  // width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-icon {
  position: absolute;
  top: -4px;
  right: 1px;
  color: #ff0000c9;
}


.mainDiv {
  // padding: 35px;
  display: flex;
  justify-content: flex-end;
}


.table_page a.label-link {
  color: #505d69;
  text-decoration: underline !important;
}

.inv2 {
  width: 25px;
  height: 25px;
  filter: grayscale(100%);
}

.inv1 {
  width: 25px;
  height: 25px;

}

.actionBtn {
  display: flex;
  // justify-content: space-evenly;
}



.table_page a.label-link {
  color: #505d69;
  text-decoration: underline !important;
}

.feature-box {
  position: relative;
  width: 25px;
  height: 25px;
}

.feature-box .feature-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px;
  display: block;
}

.feature-box i {
  font-size: 25px;
  display: block;
  line-height: 25px;
  color: $secondary;
}

.feature-box .feature-input:checked+i::before {
  content: "\f124";
}

.feature-box .feature-input:checked+i {
  animation: bell 1s ease-in-out;
}

@keyframes bell {
  0% {
    transform: rotate(35deg);
  }

  12.5% {
    transform: rotate(-30deg);
  }

  25% {
    transform: rotate(25deg);
  }

  37.5% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(15deg);
  }

  62.5% {
    transform: rotate(-10deg)
  }

  75% {
    transform: rotate(5deg)
  }

  100% {
    transform: rotate(0);
  }
}


.radioBtn {
  display: flex;
  justify-content: space-between;
}

.radio-group {
  display: flex;
  flex-direction: unset;
  gap: 23px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.radio-label input[type="radio"] {
  margin-right: 8px;
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.2s, border-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.radio-label input[type="radio"]::before {
  content: '';
  display: inline-block;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color 0.2s;
  position: absolute;
}

.radio-label input[type="radio"]:checked {
  border-color: #fcb92c;
}

.radio-label input[type="radio"]:checked::before {
  background-color: #fcb92c;
}

.address-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: block;
}


.clearBtn{
  width: auto;
  height: 28px;
  border-radius: 14px;
  padding: 0 15px;
}

.select_date .md-text {
  display: flex;
}

.select_date .primary-date-range-key {
  background: none;
  color: #343a40;
  font: 500 10px Nunito, sans-serif;
  height: 16px;
  letter-spacing: .25px;
  line-height: 16px;
  margin: auto 6px auto auto;
  padding: 0 4px;
}

.select_date .ng-binding {
  color: #343a40;
  font: 500 14px/20px Nunito, sans-serif !important;
  font-weight: 600 !important;
  text-align: right;
  display: flex;
}

.select_date {
  cursor: pointer;
}


.filter-row > div{margin-bottom: 10px;}
.select_date .ng-binding::before {
  display: none;
}
.select_date {
  border: 1px dotted black;
  border-style: groove;
  border-color: grey;
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  // padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  // padding-left: 0.5rem;
  font-size: .7875rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
}
.select_date:before {
  position: absolute;
  color:$secondary !important;
  line-height: 28px;
  right: 10px;
  top: 0;
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\eb1d";
}
.select_date .ng-binding {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.select_date .ng-binding:after {
  display: none;
}

// .dataTables_filter .form-control {
//   width: 200px !important;    margin-left: 10px !important; margin-bottom: 10px !important;
// }
// .dataTables_filter .custom-select.form-control {
//   width: 200px;    margin-left: 10px !important; margin-bottom: 10px !important;
// }

.filter-btn-col{padding: 0 5px;}
.filter-row {
  margin: 0 -5px;width: calc(100% + 10px);
  .col-md-2 {
  padding-right: 5px;
  padding-left: 5px;
}
  }

.filter-box2 .btn {right: 0;    margin-left: 0 !important;}


.table-responsive {
  overflow-x: auto;
}
.dataTables_filter {
  text-align:left;
}
.select_date .ng-star-inserted{   
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 86%;
  display: flex;
}
.modal-content {
  overflow-x: hidden !important;
}

@media (max-width: 1199px){
.filter-row {
  justify-content:flex-start !important;
}
.filter-row .col-md-2,.filter-btn-col {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}
.order_4{order: 4;}
}


@media (max-width: 769px){

  .filter-row .col-md-2,.filter-btn-col {
    flex: 0 0 50%;
    max-width: 50%;
  }

.data-filters label {
  white-space: normal !important;
  text-align: right!important;
}
.data-filters .form-control{width:calc(33.333% - 10px) !important; }
.hide-in-tab{display: none !important;}
.contents {
  width: 100%;
}
.table-responsive .table tbody td {
  width: 100% !important;
  text-align: right !important;
}
}

@media (max-width: 479px){
  .filter-row .col-md-2,.filter-btn-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dataTables_filter .form-control,
  .dataTables_filter .custom-select.form-control{width:100% !important;   margin-left: 0 !important;}

}

@media (max-width: 769px){
  .dataTables_info {
    text-align: center;
    width: 100%;
}
.paging_simple_numbers {
    display: flex;
    justify-content: center;
  }
  .feature-box {
    width: 100%;
}
.mobile-flex{display: flex !important;
  justify-content: flex-end;}
  }

  .contents{
    overflow: hidden;
    width: 171px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-decoration: underline;
  }

  .contentsForOther{
    overflow: hidden;
    width: 171px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
   
  }

  // .image-container {
  //   position: relative;
  //   // display: inline-block;
  // }
  
  // .cross-icon {
  //   position: absolute;
  //   top: -35px;
  //   right: 5px;
  //   font-size: 42px;
  //   color: red;
  //   cursor: pointer;
  // }


  .image-container {
    position: relative;
    // display: inline-block;
  }
  
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: red; /* You can change the color as needed */
    cursor: pointer;
  }

.multi-select-dropdown {
  .dropdown-list, .dropdown-list ul { width: fit-content !important; }
  .multiselect-item-checkbox input[type=checkbox]+div { width: fit-content !important; white-space: nowrap; }
  .multiselect-dropdown .dropdown-btn { border: 1px solid #ced4da !important;border-radius: 0.25rem !important;height: 29px !important;font-size: 12px !important;padding: 6px 8px !important; }
  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before { border-width: 6px 6px 0 !important; top: 50% !important; color: #000 !important;border-color: #000 transparent !important; }
  .multiselect-dropdown .dropdown-btn .selected-item-container { max-width: 70% !important; }
  .multiselect-dropdown .dropdown-btn .selected-item-container:first-child .selected-item { height: 15px !important;font-size: 12px !important;padding: 1px !important;display: flex !important;align-items: center !important;justify-content: center !important;max-width: 100% !important; }
  .multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret { transform: none !important; }
  .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span { max-width:100% !important;white-space: nowrap; }
}


.dropdown-multiselect__caret{
  width: 29px !important;
  height: 29px !important;
  background: url(../src/assets/images/select-arrow.svg) no-repeat center center !important;
  background-size: 8px 10px !important;
}
.dropdown-multiselect__caret:before{display: none !important;}
.cursor-pointer { cursor: pointer; }
.fancybox-container { z-index: 99998; }


.dropdown-list .filter-textbox input {
  background: url(../src/assets/images/search.png) no-repeat 0 center !important; background-size: 15px auto !important;
}
.dropdown-list h5{font-size: 14px;}
.multi-select-dropdown .dropdown-list ul{
  width: 100% !important;
}


.modal-body .multiselect-dropdown .dropdown-btn{
  padding: 10px 8px !important;
}
.modal-body .multi-select-dropdown .multiselect-dropdown .dropdown-btn {
  height: 39px !important;
}
.modal-body .dropdown-multiselect__caret {
  height: 37px !important;
}








.rdio {
  position: relative;
  margin-right: 15px;
}
.rdio input[type=radio] {
  opacity: 0;
}
.rdio label {
  padding-left:30px;
  cursor: pointer;
  margin-bottom: 7px !important;
  color: #2A2B2F;
font-size: 14px;
font-weight: 400;
line-height: 20px;
}
.rdio label:before {
  width:20px;
  height:20px;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: inline-block;
  // -moz-border-radius: 50px;
  // -webkit-border-radius: 50px;
  // border-radius: 50px;
  // border: 1px solid #8A8A8A;
  // background: #fff;
  background: url(assets/images/radio.svg) no-repeat center;
  background-size: 100%;
}
.rdio input[type=radio] {
  margin: 0px;
}
.rdio input[type=radio]:disabled + label {
  color: #999;
}
.rdio input[type=radio]:disabled + label:before {
  // background-color: #BA0C2F;
}
.rdio input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  top:0;
  left:0;
  display: inline-block;
  font-size: 11px;
  width:20px;
  height:20px;
  background: url(assets/images/radio-active.svg) no-repeat center;
  background-size: 100%;
}

.rdio-default input[type=radio]:checked + label:before {
  border-color: #BA0C2F;
}

.rdio-primary input[type=radio]:checked + label:before {
  border-color: #BA0C2F;
}
.rdio-primary input[type=radio]:checked + label::after {
  // background-color: #BA0C2F;
}


.radioCustom{position: relative; margin-right: 25px;}
.radioCustom input[type=radio] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.radioCustom label {
  padding-left:30px;
  cursor: pointer;
  margin-bottom: 7px !important;
  color: #2A2B2F;
font-size: 14px;
font-weight: 400;
line-height: 20px;
}
.radioCustom label:before {
  width:20px;
  height:20px;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: inline-block;
  background: url(assets/images/radio.svg) no-repeat center;
  background-size: 100%;
}
.radioCustom input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  top:0;
  left:0;
  display: inline-block;
  font-size: 11px;
  width:20px;
  height:20px;
  background: url(assets/images/radio-active.svg) no-repeat center;
  background-size: 100%;
}

.updateService #myTabContent{
  padding: 1rem !important;
 }
 
 .updateService .ng-select-container {
  height: 48px !important;
  min-height: 48px !important;
  padding: 0 10px;
}
.updateService .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 13px;
}
.updateService .ng-select .ng-select-container .ng-value-container {
  padding: 0 0;
  border-top: none;
}
.updateService label {
  font-size: 12px !important;
}
.updateService .form-control,.updateService .ng-select .ng-select-container,
.updateService label.file-label .file-custom{
  border-color: #8A8A8A !important;
}
.ng-select .ng-select-container:after{display: none !important;}
.updateService .modal-footer{padding: 0 15px !important;}
.updateService .form_row{
  margin-bottom: 0 !important;
}
.invalid{
  color: red;
}
.label-small { z-index:1002; color: #8A8A8A;font-size: 10px;font-style: normal;font-weight: 400;line-height: 140%;letter-spacing: 0.125px;position: absolute;left: 10px;top: -6px;background: #fff;padding: 0 5px;}
.form-group{position: relative;}
.mt-10{margin-top: 10px;}
.table td, .table th {
  font-size: 12px;
}
 table td h6, table td h5{color: #000;
  font-weight: 600;
  font-family: Nunito, sans-serif;
  font-size:12px; margin: 0;}

  .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
}
  
.edit-pencil{margin: 0 0 0 5px !important; display: inline-block; vertical-align: top;}

.view-details{
  padding: 0!important;
  right: 0 !important;
  p{margin: 0;}
}
.unread-filter-btn {
width: 100%;
justify-content: center;
}
@media (max-width: 767px){
  .moderate-order-amount{
    span{    text-align: right;
      width: 100%;}
  }
  .updateService .add_product_form {
    padding: 0 !important;
}
.actionBtn {
  justify-content: end;
}
.mobile-center{text-align: center;}
ul.ngx-pagination {
  margin: 15px 0 0 0 !important;
  justify-content: center !important;
}
.footer {
  text-align: center;
}
.total-order-count,.per-page-unread-count {
  float: none !important;
  display: inline-block !important;
  vertical-align: top !important;
}
.table-responsive .table tbody td{        min-width: 100%;}
.table-responsive .table tbody td p{width: 100% !important;        margin: 0;}
.table-responsive .table tbody td:last-child{justify-content: end;}

.basic_profile.table td {
  font-size: 12px;
  padding: 10px 0;

  &:last-child {
      word-break: break-all;
  }
  img{height: 35px;
      width: auto;}

  h5 {
        color: #000;
        font-weight: 600;
        font-family: Nunito, sans-serif;
        font-size:12px;
    }   

  h6 {
      color: #000;
      font-weight: 600;
      font-family: Nunito, sans-serif;
      font-size: 12px;
      margin: 0;
  }

  .col-4 {
      flex: 0 0 100%;
      max-width: 100%;
      .form-control {
          padding: 5px;
          font-size: 12px;
      }
  }
}

.table_page .card-body .table td .actionBtn{
  justify-content: end !important;}
}

.invalid-feedback { display: block !important;position: absolute; }
.requiredFiled{
  color: #dc3545;
    font-size: 80%;
}


.is-unread { background-color: #d5e6fb; }
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.unread-filter-box { width: 75px; min-width: 75px; max-width: 90px !important; }
.unread-filter-btn { height: 29px;position: relative !important;left: 0px;display: flex;align-items: center;background-color: transparent;color: #505d69 !important;border-color: #ced4da;font-weight: 400;font-size: .7875rem;}
.unread-filter-btn.active { border-color: #002f6c;color: #002f6c; }
.part-payment-filter-box { width: 120px; min-width: 120px; max-width: 120px !important; }
.part-payment-filter-btn { height: 29px;position: relative;right: 0px;display: flex;align-items: center;background-color: transparent;color: #505d69 !important;border-color: #ced4da;font-weight: 400;font-size: .7875rem;}
.part-payment-filter-btn.active { border-color: #002f6c;color: #002f6c; }
.per-page-unread-count { background-color: #cbf1d6;padding: 10px;border-radius: 4px;border: 1px solid #88dba0;float: inline-end;margin-top: 15px;}
.total-order-count { background-color: #cbf1d6;padding: 10px;border-radius: 4px;border: 1px solid #88dba0;float: inline-end;margin-top: 15px;}
.superadmin-color {
  color: rgb(75, 71, 71);
}
.superadmin-color:hover {
  color: rgb(75, 71, 71);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.keywords-listing-on-table{max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.MsoNormalTable{width: 100% ;max-width: 100%; margin: 10px 0;}
.MsoNormalTable td{padding: 5px;white-space: normal;}